import { useState } from 'react'
import {
  Form,
  FormField,
  FormFieldError,
  FormFieldHint,
  FormFieldLabel,
  FormInput,
  FormSubmitButton
} from '~/components/Form'
import { useForm } from '~/hooks/useForm'
import { CreateOrganizationSchema } from './routeConfig'

type CreateOrgFormProps =
  | {
      successPath: string
      onSuccess?: never
    }
  | {
      successPath?: never
      onSuccess: () => void
    }

export const CreateOrgForm = ({
  successPath,
  onSuccess
}: CreateOrgFormProps) => {
  const [key, setKey] = useState(Date.now())

  const fetcherKey = `${CreateOrganizationSchema.shape.intent.value}_${key}`

  const form = useForm({
    schema: CreateOrganizationSchema,
    navigate: !!successPath,
    action: '/api/organizations',
    include: { successPath },
    fetcherKey,
    onSuccess: () => {
      setKey(Date.now())
      onSuccess?.()
    }
  })

  return (
    <Form {...form} key={key}>
      <div className="space-y-4">
        <FormField name={form.keys.name}>
          <FormFieldLabel>Name</FormFieldLabel>
          <FormInput icon="building" placeholder="e.g. Pied Piper" />
          <FormFieldHint>You can always change this later.</FormFieldHint>
          <FormFieldError />
        </FormField>

        <FormSubmitButton className="w-full">Create</FormSubmitButton>
      </div>
    </Form>
  )
}
